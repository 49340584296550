import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { setToken, setUserLocalStorage } from "helpers";

const Login = () => {
  const { isLoading, user, isAuthenticated, getAccessTokenSilently } =
    useAuth0();

  async function getUserApi(token) {
    const api = `${process.env.NEXT_PUBLIC_AUTH0_API}/info?token=${token}&apikey=clarin`;
    
    await fetch(api, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },
    })
      .then((response) => response.json())
      .then((data) => {
        storageUser(data, token);
      })
      .catch((error) => {
        redirectOrigin();
        console.log(error);
      });
  }

  const cookieSuscription = (suscription) => {
    let expDate = new Date();
    expDate.setHours(expDate.getHours() + 24);
    document.cookie =
      "statusSus" +
      "=" +
      suscription +
      "; expires=" +
      expDate.toUTCString() +
      "; path=/";
  };

  const getCookie = (cname) => {
    let name = cname + "=";
    let ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };

  const deleteCookie = (cookie) => {
    document.cookie =
      cookie + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  };

  const redirectOrigin = () => {
    if (getCookie("auth0_redir") !== "") {
      let cookie = getCookie("auth0_redir").split("?")[0];
      deleteCookie("auth0_redir");
      location.href = cookie;
    } else {
      location.href = "/";
    }
  };

  const storageUser = (response, token) => {
    setUserLocalStorage(user, response);
    //fix
    const susStatus = (!!response?.user)
      ? response.user?.data?.subscriptions?.map((sus) => sus?.pId)
      : "";
    const suscription = susStatus?.includes("CLARIN_C") || susStatus?.includes("CLARIN_D") || susStatus?.includes("CLA_OLE") ? "1" : "0";
    cookieSuscription(suscription); 
    //cookieSuscription(response.user != null ? "1" : "0");    
    setToken(token);
    redirectOrigin();
  };

  const initialize = async () => {
    if (isAuthenticated) {
      const token = await getAccessTokenSilently();
      getUserApi(token);
    } else {
      redirectOrigin();    
    }
  };

  useEffect(() => {
    if (!isLoading) {
      initialize();
    }
  }, [isLoading]);

  return <></>;
};

export default Login;
